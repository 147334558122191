import * as React from 'react';
import {
  Card,
  CardBody,
  TabStrip,
  TabStripTab,
} from '@progress/kendo-react-layout';
import TestCaseArguments from '../../../TestCaseArguments/Pages/TestCaseArguments';
/**
 * Test Details Desktop view
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} The DesktopView component.
 */
const DesktopView = ({ selected,
  handleSelect,detailsDataModified, state}) => {

  const [isReady, setIsReady] = React.useState(false);
  return (
    <>
      <Card>
        <TabStrip
          className="page-tabs"
          selected={selected}
          onSelect={handleSelect}
        >
          <TabStripTab
            title="Test Case Argument"
            contentClassName={`tabs-card ${
              detailsDataModified === true ? 'tab-modified' : ''
            }`}
          >
            <Card
              className={`tabs-card ${
                detailsDataModified === true ? 'tab-modified' : ''
              }`}
            >
              <CardBody>
                <div className="card-body ">
                  <TestCaseArguments test={state.dataSet}/>
                </div>
              </CardBody>
            </Card>
          </TabStripTab>
        </TabStrip>
      </Card>
    </>
  );
};
export default DesktopView;
