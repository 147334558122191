import React from "react";
import { convertToLocalDate } from "../../../../Utils/Common/utils";
/**
 * data Columns for grid and mobile view
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} The Data Columns Array
 */
export const DataColumns = (editScreen) => {
  const dataColumns = [
    {
      field: "row_no",
      title: "Row no",
      editable: false,
      show: false,
      width: "250px",
      width: "250px",
      minResizableWidth: 150,
      filterable: true,
      sortable: false,
    },
    {
      field: "uc_ossi_test_case_id",
      title: "Test Case",
      editable: false,
      show: true,
      width: "300px",
      minResizableWidth: 50,
      cell: (props) => (
        <td
          className="edit-link"
          onClick={() => {
            editScreen(props.dataItem);
          }}
        >
          {props.dataItem.uc_ossi_test_case_id}
        </td>
      ),
    },
    {
      field: "uc_ossi_app_test_id",
      title: "Test",
      editable: false,
      show: true,
      width: "250px",
      minResizableWidth: 50,
    },
    {
      field: "TenantName",
      title: "Tenant",
      editable: false,
      show: true,
      width: "250px",
      minResizableWidth: 150,
      sortable: false,
    },
    {
      field: "uc_ossi_descr",
      title: "Description",
      editable: false,
      show: true,
      width: "250px",
      minResizableWidth: 50,
    },
    {
      field: "uc_ossi_cmd",
      title: "Command",
      editable: false,
      show: true,
      width: "250px",
      minResizableWidth: 50,
    },
    {
      field: "uc_ossi_pre_exec_cmd",
      title: "Pre Execution Command",
      editable: false,
      show: true,
      width: "250px",
      minResizableWidth: 50,
    },
    {
      field: "uc_ossi_post_exec_cmd",
      title: "Post Execution Command",
      editable: false,
      show: true,
      width: "250px",
      minResizableWidth: 50,
    },
    {
      field: "uc_ossi_validate_cmd",
      title: "Validation Command",
      editable: false,
      show: true,
      width: "250px",
      minResizableWidth: 50,
    },
    {
      field: "uc_ossi_expected_exec_ms",
      title: "Exection Millisecond",
      editable: false,
      show: true,
      width: "200px",
      minResizableWidth: 50,
    },
    {
      field: "uc_ossi_expected_lines_cmd",
      title: "Expected Command",
      editable: false,
      show: true,
      width: "200px",
      minResizableWidth: 50,
    },
    {
      field: "uc_ossi_grp",
      title: "Group",
      editable: false,
      show: true,
      width: "200px",
      minResizableWidth: 50,
    },
    {
      field: "uc_ossi_disable_flg",
      title: "Disabled?",
      editable: false,
      show: true,
      width: "200px",
      minResizableWidth: 50,
    },
    {
      field: "ins_dt",
      title: "Created Date",
      editable: false,
      show: true,
      width: "200px",
      minResizableWidth: 50,
      filterable: false,
      cell: (props) => <td>{convertToLocalDate(props.dataItem.ins_dt)}</td>,
    },
    {
      field: "last_upd_dt",
      title: "Modified Date",
      editable: false,
      show: true,
      width: "200px",
      minResizableWidth: 50,
      filterable: false,
      cell: (props) => (
        <td>{convertToLocalDate(props.dataItem.last_upd_dt)}</td>
      ),
    },
    {
      field: "ins_user_id",
      title: "Created By",
      editable: false,
      show: true,
      width: "200px",
      minResizableWidth: 50,
    },
    {
      field: "last_upd_user_id",
      title: "Modified By",
      editable: false,
      show: true,
      width: "200px",
      minResizableWidth: 50,
    },
  ];

  return dataColumns;
};
export default DataColumns;
